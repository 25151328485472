// Note: This is used only in app/views/outbound/uld_builds/building/delete.html.haml
// Which is shown in /outbound/uld_builds when moving master from  the Beginn
// step to the Ende step and used when there is more than one ULD (according to Kontour)
// It might be worth moving this code together with other related code when cleaning up
// fields related to /outbound/uld_builds

window.UldHelper = {
  markDuplicates() {
    const findSurroundingDiv = function (textarea) {
      const inputFieldId = $(textarea).attr('id') + '-selectized';
      const inputField = $(document.getElementById(inputFieldId));
      const surroundingDiv = inputField.parent();
      return surroundingDiv;
    };
    const textareas = $('textarea[id^=outbound_uld_build_uld_numbers]');
    const idValueMap = {};
    textareas.each(function (index, textarea) {
      const textareaVal = $(textarea).val();
      if (textareaVal) {
        if (idValueMap[textareaVal]) {
          idValueMap[textareaVal].push(textarea);
        } else {
          idValueMap[textareaVal] = [textarea];
        }
      }
    });
    const form = $('form[id^=edit_outbound_uld_build]');
    const button = form.find(':submit');
    button.removeAttr('disabled');
    textareas.each(function (_index, textarea) {
      findSurroundingDiv(textarea).removeClass('required-selectized');
    });
    $.each(idValueMap, function (textareaVal, textareas) {
      if (textareas.length >= 2) {
        $.each(textareas, function (_index, textarea) {
          findSurroundingDiv(textarea).addClass('required-selectized');
          button.attr('disabled', true);
        });
      }
    });
  },
};
