const handleDateInputs = (form) => {
  const startDateInput = form.find('input.date[name=start]');
  const endDateInput = form.find('input.date[name=end]');
  const dateSetterButtons = form.find('button[data-form-action=setDateRange]');

  dateSetterButtons.click(function () {
    startDateInput.val($(this).data('start-date'));
    endDateInput.val($(this).data('end-date'));
    startDateInput.trigger('change');
    endDateInput.trigger('change');
  });

  const markMatchingButtonsAsActive = function () {
    const mark = function (el) {
      $el = $(el);
      if (
        startDateInput.val() === $el.data('start-date') &&
        endDateInput.val() === $el.data('end-date')
      ) {
        $el.addClass('active');
      } else {
        $el.removeClass('active');
      }
    };
    Array.from(dateSetterButtons).forEach((button) => mark(button));
  };
  markMatchingButtonsAsActive();

  startDateInput.change(() => markMatchingButtonsAsActive());
  endDateInput.change(() => markMatchingButtonsAsActive());
};

const handleDepotEmail = (form) => {
  const formsWithDepotEmail =
    '#reports-loose-check-reports-form, #reports-uld-build-reports-form';
  if (form.is(formsWithDepotEmail)) {
    const depotEmails = form.data('depotEmails');
    const depotSelect = form.find('select[name=depot_id]');
    const emailInput = form.find('input[name=email]');

    // populate depot-email only if there is no initial user-email set
    if (emailInput.val() === '') {
      $(depotSelect).on('change', function () {
        const selectedDepotId = $(this).val();
        emailInput.val(depotEmails[selectedDepotId]);
      });
      depotSelect.trigger('change');
    }
  }
};

const handlePouchScope = (form) => {
  const formsWithPouch = '#pouch-reports-form';
  if (form.is(formsWithPouch)) {
    const scopeSelect = form.find('select[name=scope]');
    scopeSelect.change(function () {
      if ($(this).val() === 'depot') {
        form.find('.field.client').hide();
        form.find('.field.depot').show();
      } else if ($(this).val() === 'client') {
        form.find('.field.client').show();
        form.find('.field.depot').hide();
      } else {
        form.find('.field.client').hide();
        form.find('.field.depot').hide();
      }
    });
    scopeSelect.trigger('change');
  }
};

document.addEventListener('DOMContentLoaded', () => {
  const legacyReportSelector =
    '#export-tonnage-reports-form, #import-tonnage-reports-form, #pouch-reports-form, #reports-loose-check-reports-form, #reports-master-loads-reports-form,#reports-uld-build-reports-form, #spx-reports-form, #sniffer-usage-reports-form, #spx-reports-form, #tour-statistics-reports-form, #warehouse-manager-reports-form, #xray-sniffer-usage-reports-form, #xray-usage-reports-form';
  const form = $(legacyReportSelector);

  if (form) {
    handleDateInputs(form);
    handleDepotEmail(form);
    handlePouchScope(form);
  }
});
